<template>
  <div><slot /></div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SbDocument',
});
</script>
