<template>
  <hr />
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SbHorizontalRule',
});
</script>
