<template>
  <u><slot /></u>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SbUnderline',
});
</script>
