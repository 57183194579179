<template>
  <s><slot /></s>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SbStrike',
});
</script>
